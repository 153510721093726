span.required,
label.has-error {
    color: #A94442;
}

.form-control {
    height: auto;
}

.list-group-item label.has-error {
    margin: 0;
}

.form-review-label {
    text-align: left;
}

.form-product {
    width: 20px;
    height: 20px;
}

.form-review-product-title {
    margin-top: 0;
}

.form-review-row,
.form-review-product-row {
    border-top: 1px solid #DDD;
    padding: 8px;
}

.form-review-row:nth-child(odd) {
    background: #F9F9F9;
}

.form-review-legend {
    margin-top: 25px;
    margin-bottom: 0;
    padding-bottom: 2px;
}

.form-review-row:first-child {
    border-top: none;
}

.form-captcha {
    margin-top: 25px;
}

.form-captcha .form-group {
    margin-top: 20px;
}

.form-review-row ul {
    margin-bottom: 0px;
}

.form-header {
    margin: 0 -15px;
}

.form-header .jumbotron {
    padding: 10px 20px 15px;
    box-shadow: none;
}

.form-header .jumbotron h1 {
    font-size: 40px;
    margin-top: 10px;
}

#form-index {
    padding-top: 20px;
    padding-bottom: 20px;
}

.form-header .form-steps {
    color: rgba(0,0,0,.4);
    font-size: 15px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
}

.form-header .form-steps ul {
    margin-top: 15px;
    margin-bottom: 5px;
}

.form-steps .active span {
    color: rgba(0,0,0,.8);
    font-weight: bold;
}

.form-header .tnt-angle-right {
    color: rgba(0, 0, 0, 0.2);
    margin-left: 5px;
    margin-right: 7px;
}

.form-header .jumbotron p {
    font-size: 15px;
    font-weight: normal;
    margin-bottom: 10px;
    margin-top: 15px;
}

.date.dpdate .input-group-addon {
    cursor: pointer;
    background-color: #FFF;
}

.multi-list .checkbox-none {
    display: none;
}

.form-review-print {
    margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
    .form-review-label {
        text-align: right;
    }
}

@media screen and (max-width: 767px) {
    .form-header .jumbotron { padding: 10px; }
    .form-header .jumbotron h1 { font-size: 24px; }
}

@media print {
    #form-index .main-sidebar,
    #tncms-region-global-side-primary,
    #tncms-region-global-side-secondary,
    #tncms-region-global-side-tertiary,
    #tncms-region-global-container-bottom,
    #tncms-region-global-container-bottom-fullscreen,
    #tncms-region-global-container-top-fullscreen,
    #tncms-region-global-container-top,
    #tncms-region-global-skyline,
    #tncms-region-global-sidebar-top,
    #tncms-region-global-sidebar-bottom {
        display: none;
    }
}